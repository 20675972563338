/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import "../css/layout.css"
import Footer from "./footer";
import CookieConsent from "react-cookie-consent";

const Layout = ({children}) => {


    return (
        <>

            <div
                style={{
                    margin: `0 auto`,
                    // maxWidth: 960,
                    // padding: `0 1.0875rem 1.45rem`,
                }}
            >
                <main>{children}</main>
                <Footer />
                <CookieConsent
                    location="bottom"
                    buttonText="Accept"
                    declineButtonText="Decline"
                    enableDeclineButton
                    cookieName="gatsby-gdpr-google-analytics"
                    style={{background: "#152e4d"}}
                    disableButtonStyles={true}
                    buttonClasses="py-2 px-8
                    text-primary-lighter border-solid border border-primary-lighter
                    hover:text-blue-dark hover:bg-primary-lighter focus:outline-none
                    text-base font-medium text-center transition-colors duration-200 rounded-3xl"
                    declineButtonClasses="py-2 px-8 m-2 text-primary-pink border-solid border border-primary-lighter
                                        hover:text-light hover:bg-primary-pink focus:outline-none
                                        text-base font-medium text-center transition-colors duration-200 rounded-3xl"
                >
                    This site uses cookies and similar tools that are necessary to improve your experience and to provide our services. We also use these cookies to understand how customers use our services so we can make improvements.
                </CookieConsent>
            </div>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
