import React from 'react';
import Telegram from "../images/telegram.inline.svg";
import Twitter from "../images/twitter.inline.svg";

const Footer = () => (
    <>
        {/*<footer className="container mx-auto py-16 px-3 mt-48 mb-8 text-gray-800">*/}
        {/*  <div className="flex -mx-3">*/}
        {/*    <div className="flex-1 px-3">*/}
        {/*      {new Date().getFullYear()}*/}
        {/*    </div>*/}
        {/*    <div className="flex-1 px-3">*/}
        {/*      <h2 className="text-lg font-semibold">About Us</h2>*/}
        {/*      <p className="mt-5">We are a new social platform about trades</p>*/}
        {/*    </div>*/}
        {/*    <div className="flex-1 px-3">*/}
        {/*      <h2 className="text-lg font-semibold">Important Links</h2>*/}
        {/*      <ul className="mt-4 leading-loose">*/}
        {/*        <li>*/}
        {/*          <a href={`${process.env.GATSBY_MY_APP_URL}/terms`}>Terms &amp; Conditions</a>*/}
        {/*        </li>*/}
        {/*        <li>*/}
        {/*          <a href={`${process.env.GATSBY_MY_APP_URL}/privacyPolicy`}>Privacy Policy</a>*/}
        {/*        </li>*/}
        {/*      </ul>*/}
        {/*    </div>*/}
        {/*    <div className="flex-1 px-3">*/}
        {/*      <h2 className="text-lg font-semibold">Social Media</h2>*/}
        {/*      <ul className="mt-4 leading-loose">*/}
        {/*        <li>*/}
        {/*          <a href="#">Facebook</a>*/}
        {/*        </li>*/}
        {/*        <li>*/}
        {/*          <a href="#">Twitter</a>*/}
        {/*        </li>*/}
        {/*      </ul>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</footer>*/}
        <footer className=" mt-auto flex items-center justify-between px-2 md:px-32 py-10">
            <div className="text-white">
                &copy; Trades League {new Date().getFullYear()} | All rights reserved
            </div>
            <div className="flex items-center justify-center">
                <div className="px-4">
                    <a href={`${process.env.GATSBY_MY_APP_URL}/terms`}
                       className="transition-colors duration-200
                            text-primary-lighter hover:text-white
                            focus:outline-none focus:ring-primary-darker">Terms &amp; Conditions</a>
                </div>
                <div className="px-4">
                    <a href={`${process.env.GATSBY_MY_APP_URL}/privacyPolicy`} className="transition-colors duration-200
                            text-primary-lighter hover:text-white
                            focus:outline-none focus:ring-primary-darker">Privacy Policy</a>
                </div>
                <div className="px-4">
                    <a href={`${process.env.GATSBY_WHITE_PAPER_URL}`}
                       target="_blank"
                       className="transition-colors duration-200
                            text-primary-lighter hover:text-white
                            focus:outline-none">White Paper</a>
                </div>
                <div className="px-4">
                    <a href="https://twitter.com/TradesLeagueCom"
                       target="_blank"
                       className="transition-colors duration-200
                            text-primary-lighter
                            hover:filter hover:drop-shadow-blue-dark
                            focus:outline-none">
                        <Twitter />
                    </a>
                </div>
                <div className="px-4">
                    <a href="https://t.me/tradesleague"
                       target="_blank"
                       className="transition-colors duration-200
                            text-primary-lighter
                            hover:filter hover:drop-shadow-blue-dark
                            focus:outline-none">
                        <Telegram />
                    </a>
                </div>
            </div>

        </footer>
    </>
);

export default Footer;
